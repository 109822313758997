<template>
  <div class="page">
    <gc-header header-data="alarmMessage"> </gc-header>
    <div class="page-main">
      <div class="page-main-left">
        <gc-sub-title icon="icon-shebei1" text="配置列表" />
        <div style="margin-top: 12px; padding-right: 10px; text-align: right">
          <el-radio-group
            v-model="configType"
            size="small"
            @input="handleConfigTypeChange"
          >
            <el-radio-button label="1">告警类型</el-radio-button>
            <el-radio-button label="2">表类型</el-radio-button>
          </el-radio-group>
        </div>
        <div style="padding-right: 10px">
          <gc-custom-search
            class="box-select"
            key-word="设备类型"
            type="select"
            width="100%"
            filterable
            :search.sync="deviceTypeId"
            :search-option="typeList"
            v-show="configType === '1'"
          />
        </div>
        <!-- 告警类型 -->
        <ul class="items" v-show="configType === '1'" v-loading="loading">
          <li
            class="item"
            v-for="item in paramList"
            :key="item.eventId"
            :class="{ active: activeConfigId === item.eventId }"
            @click="handleClickEvent(item.eventId)"
          >
            {{ (item.eventModel && item.eventModel.name) || "--" }}
          </li>
        </ul>
        <!-- 表类型 -->
        <ul class="items" v-show="configType === '2'">
          <li
            class="item"
            v-for="item in typeList"
            :key="item.deviceTypeId"
            :class="{ active: activeConfigId === item.deviceTypeId }"
            @click="
              activeConfigId = item.deviceTypeId;
              queryAlarmPushConfig();
            "
          >
            {{ item.deviceTypeName }}
          </li>
        </ul>
      </div>
      <div class="page-main-right" v-loading="configLoading">
        <gc-detail-tab
          ref="tab"
          v-if="tabList.length"
          :is-edit="true"
          :tab-list="tabList"
        ></gc-detail-tab>
        <gc-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import TabContent from "./TabContent.vue";
import { apiGetAlarmPush, apiGetAlarmConfig } from "@/api/alarm";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "alarmMessage",
  data() {
    return {
      typeList: [],
      deviceTypeId: null,
      paramList: [],
      tabList: [],
      tabListCache: [], // 备份，取消时使用
      activeConfigId: null,
      loading: false,
      configLoading: false,
      configType: "1",
    };
  },
  computed: {
    ...mapGetters({
      isolationOrgId: "isolationOrgId",
    }),
  },
  watch: {
    isolationOrgId() {
      Object.assign(this.$data, this.$options.data());
      this.queryAlarmPushConfig();
    },

    deviceTypeId(newVal) {
      if (newVal) this.getAlarmConfigList(true);
    },
  },
  methods: {
    ...mapActions({
      judgeNeedPickOrg: "user/judgeNeedPickOrg",
      getDeviceTypes: "common/getDeviceTypeList",
    }),

    queryAlarmPushConfig() {
      this.tabList = [];
      this.configLoading = true;
      const isAlarmConfig = this.configType === "1";
      apiGetAlarmPush({
        deviceTypeId: isAlarmConfig ? this.deviceTypeId : this.activeConfigId,
        eventId: isAlarmConfig ? this.activeConfigId : null,
      })
        .then((data) => {
          this.tabList = data.map((o) => {
            return {
              ...o,
              label: `${o.name}业务`,
              component: TabContent,
              data: {
                pushChannel: o.pushChannel,
                receiveStaffList: o.messagePushRspVO?.receiveStaffs || [],
                messagePushConfigId: o.messagePushRspVO?.messagePushConfigId,
                deviceTypeId: this.deviceTypeId,
                eventId: this.activeConfigId,
                pushMode: isAlarmConfig ? 3 : 2,
                isAlarmConfig,
              },
            };
          });
        })
        .finally(() => {
          this.configLoading = false;
        });
    },

    handleConfigTypeChange(configType) {
      if (configType === "1") {
        this.activeConfigId = this.paramList[0]?.eventId;
      } else {
        this.activeConfigId = this.typeList[0]?.deviceTypeId;
      }
      this.queryAlarmPushConfig();
    },

    handleClickEvent(eventId) {
      if (this.activeConfigId === eventId) return;
      this.activeConfigId = eventId;
      this.queryAlarmPushConfig();
    },

    async getDeviceTypeList() {
      const data = await this.getDeviceTypes();
      this.typeList = data;
      this.deviceTypeId = data[0]?.deviceTypeId;
    },

    async getAlarmConfigList() {
      try {
        this.loading = true;
        const data = await apiGetAlarmConfig(this.deviceTypeId);
        this.paramList = data.list || [];
        this.activeConfigId = this.paramList[0]?.eventId;
        this.queryAlarmPushConfig();
      } catch (error) {
        this.paramList = [];
        this.activeConfigId = null;
        this.queryAlarmPushConfig();
      } finally {
        this.loading = false;
      }
    },
  },
  activated() {
    this.judgeNeedPickOrg().then((flag) => {
      if (flag) {
        this.getDeviceTypeList();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.el-button {
  width: 100px;
  height: 36px;
}
.gc-detail-tab {
  flex: 1;
  overflow: hidden;
}

.page-main {
  &-left {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 12px 15px 20px;
    .box-select {
      margin-top: 12px;
    }
    .items {
      margin-top: 10px;
      overflow-y: scroll;
      height: 0;
      flex: 1;
      .item {
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        padding: 0 13px;
        background: #f0f0f0;
        font-weight: 400;
        color: #666666;
        border-radius: 8px;
        &.active {
          background: #ebedfa;
          color: #4d6bff;
        }
        + .item {
          margin-top: 10px;
        }
      }
    }
  }
  &-right {
    padding: 12px 24px;
    ::v-deep .el-tabs__nav-scroll {
      .el-tabs__item {
        &.is-top:last-child {
          padding-right: 0;
        }
        &.is-top:nth-child(2) {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
