<template>
  <gc-dialog
    title="选择员工"
    width="1090px"
    :show.sync="visible"
    :okLoading="submitLoading"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <div class="filter">
      <gc-custom-search
        class="search"
        key-word="所属部门"
        type="cascader"
        width="280px"
        :search-option="organizeOptions"
        :search-option-key="{
          label: 'name',
          value: 'orgCode',
          checkStrictly: true,
        }"
        :search.sync="params.isolationOrgCode"
      ></gc-custom-search>
      <gc-custom-search
        class="search"
        key-word="员工名称"
        width="280px"
        :search.sync="params.staffName"
      ></gc-custom-search>
      <gc-custom-search
        class="search"
        key-word="员工岗位"
        type="select"
        width="280px"
        :search-option="roleOptions"
        :search-option-key="{ label: 'name', value: 'id' }"
        :search.sync="params.roleId"
        needAllForSearch
      ></gc-custom-search>
      <div class="btn-ops">
        <el-button
          v-click-blur
          type="primary"
          class="query"
          @click="handleQuery"
        >
          查 询
        </el-button>
        <el-button v-click-blur @click="handleReset">重 置</el-button>
      </div>
    </div>
    <gc-table
      ref="multipleTable"
      v-loading="loading"
      style="height: 500px"
      :need-type="pushChannel === 5 ? null : 'selection'"
      :isHighlightCurrent="pushChannel === 5"
      :columns="columns"
      :table-data="tableData"
      :border="true"
      @selectChange="selectChange"
      @row-click="handleRowClick"
    ></gc-table>
  </gc-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { apiGetStaffRoleList, apiGetOrganizeList } from "@/api/organizeRole";
import { apiSetAlarmPush } from "@/api/alarm";
export default {
  name: "StaffDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pushChannel: {
      type: Number,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    selectData: {
      type: Array,
      default: () => [],
    },
    alarmConfigInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      selectTemp: [],
      roleOptions: [],
      organizeOptions: [],
      params: {
        isolationOrgCode: [],
        staffName: null,
        roleId: null,
      },
      loading: false,
      submitLoading: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.getRoleOptionList();
        this.getOrganizeList();
        this.getTableData();
      }
    },
  },

  methods: {
    ...mapActions({
      getStaffList: "apiCache/getStaffList",
    }),

    async getRoleOptionList() {
      const data = await apiGetStaffRoleList();
      this.roleOptions = data || [];
    },

    async getOrganizeList() {
      const data = await apiGetOrganizeList();
      this.organizeOptions = data?.children || [];
    },

    getTableData() {
      this.loading = true;
      const isolationOrgCode = this.params.isolationOrgCode || [];
      this.getStaffList({
        ...this.params,
        isolationOrgCode: isolationOrgCode[isolationOrgCode.length - 1],
      })
        .then((data) => {
          this.tableData = data;
          this.$nextTick(() => {
            if (this.pushChannel === 5) {
              this.$refs.multipleTable.setCurrentRow(this.selectData[0]);
              this.selectTemp = [this.selectData[0]].filter(Boolean);
            } else {
              this.toggleSelection(this.selectData);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectChange(rows) {
      if (this.pushChannel !== 5) {
        this.selectTemp = rows || [];
      }
    },

    handleRowClick({ row }) {
      if (this.pushChannel === 5) {
        if (this.selectTemp[0]?.id === row.id) {
          this.selectTemp = [];
          return this.$refs.multipleTable.setCurrentRow();
        }
        this.selectTemp = [row];
      }
    },

    async handleOk() {
      const hasPhone = [...this.selectTemp].every((item) => item.phone);
      if (!hasPhone && this.pushChannel === 2) {
        return this.$message.error("所选员工需要填写手机号");
      }
      const {
        deviceTypeId,
        eventId,
        pushMode,
        messagePushConfigId,
        isAlarmConfig,
      } = this.alarmConfigInfo;
      const searchParams = {
        deviceTypeId: isAlarmConfig ? deviceTypeId : eventId,
        eventId: isAlarmConfig ? eventId : null,
        pushMode,
        messagePushConfigId,
        pushChannel: this.pushChannel,
        receiveList: this.selectTemp.map((o) => o.id).join(","),
      };
      try {
        this.submitLoading = true;
        await apiSetAlarmPush(searchParams);
        this.$emit("update", this.selectTemp);
        this.visible = false;
      } finally {
        this.submitLoading = false;
      }
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          const target = this.tableData.find((item) => item.id === row.id);
          if (target) {
            this.$refs.multipleTable.clearCheckTableSelection(target);
          }
        });
      } else {
        this.$refs.multipleTable.clearCheckTableSelection();
      }
    },

    handleQuery() {
      this.getTableData();
    },

    handleReset() {
      for (const key in this.params) {
        this.params[key] = null;
      }
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .pagination-box {
    position: absolute;
    bottom: 0;
  }
  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 24px;
    flex-wrap: wrap;
    .search {
      margin-right: 20px;
      margin-top: 20px;
    }
    .btn-ops {
      margin-top: 20px;
      margin-left: auto;
      .el-button {
        border-radius: 8px;
      }
    }
  }
}
</style>
